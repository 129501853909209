<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm8>
        <div class="overlay attendance">
          <v-card class="primary--border" outlined>
            <v-card-title
              style="border-bottom:1px solid #ccc"
              class="title mb-3"
            >
              Single SMS
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text class="pb-1 mt-5">
              <v-form
                ref="form"
                @submit.prevent="save"
                @keydown.native="form.errors.clear($event.target.name)"
                @keyup.enter="save"
                lazy-validation
              >
                <v-user-search-field
                  @onSelectUser="searchUser"
                ></v-user-search-field>
                <v-text-field
                  :disabled="user !== null && user.primary_mobile !== null"
                  v-mask="'9#########'"
                  outlined
                  dense
                  autocomplete="off"
                  label="Mobile*"
                  required
                  class="pa-0"
                  v-model="form.mobile"
                  name="mobile"
                  @keyup="mobileChange"
                  :error-messages="form.errors.get('mobile')"
                />
                <v-select
                  outlined
                  dense
                  :items="templates"
                  class="pa-0 pt-3"
                  label="Select Template"
                  v-model="template"
                />
                <div v-if="Object.keys(sms_stats).length > 0">
                  <v-sms-input-field
                    v-model="form.message"
                    :hide-balance="true"
                    :show-alert="false"
                    :balance-stats="sms_stats"
                    :default-placeholder="[]"
                    :default-sms="form.message"
                  ></v-sms-input-field>
                </div>
              </v-form>
            </v-card-text>

            <v-card-actions v-if="smsServiceStatus">
              <v-spacer></v-spacer>
              <v-btn
                block
                color="success"
                outlined
                @click="save"
                :disabled="btnDisable || form.mobile.length !== 10"
                >{{ btnText }}</v-btn
              >
            </v-card-actions>
          </v-card>

          <div
            :class="
              'inner-overlay ' + (rate_per_sms > balance ? '' : 'dont-show')
            "
          >
            <p>
              <v-icon style="font-size: 50px;color:red;">warning</v-icon>
              <br />
              Insufficient Balance. <br />
              <span style="font-size: 15px;">
                Looks like you less that minimum balance required to send
                SMS.</span
              >
            </p>
          </div>
        </div>
      </v-flex>

      <v-flex xs12 sm4 v-if="smsServiceStatus">
        <!-- <v-card class="primary--border elevation-0" outlined>
          <v-card-text class="pb-1">
            <v-data-table
              :loading="fetchingStats"
              hide-default-footer
              :items="[
                { text: 'Balance' },
                { text: 'Rate Per SMS' },
                { text: 'In Queue' },
              ]"
              :headers="[
                ...[
                  { text: 'Head', align: 'left', sortable: false },
                  { text: '#', align: 'right', sortable: false },
                ],
              ]"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <th>{{ item.text }}</th>
                  <td class="text-right">
                    <span v-if="item.text === 'Balance'">{{
                      balance.numberFormat()
                    }}</span>
                    <span v-else-if="item.text === 'Rate Per SMS'">{{
                      rate_per_sms.numberFormat()
                    }}</span>
                    <span v-else-if="item.text === 'In Queue'">{{
                      sms_queue
                    }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <br />
          </v-card-text>
        </v-card> -->

        <v-sms-stats
          :loading="fetchingStats"
          :smsQueue="sms_queue"
          :balance="balance"
          :ratePerSMS="rate_per_sms"
          
        ></v-sms-stats>
      </v-flex>
      <v-flex xs12 sm4 v-else>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon> &nbsp;SMS Service is not Activated
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="pb-1">
            <p>Please contact our support for activation SMS Service.</p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import SMSCalculator from "@/library/SMS";
import helper from "@/library/helpers";
export default {
  data: () => ({
    template: "",
    fetchingStats: false,
    form: new Form(
      {
        sms_type: "s",
        msg_type: 1,
        // file: '',
        mobile: "",
        message: "",
        sms_length: 1,
        sms_for:"notice",
      },
      "/api/sms/queue"
    ),
    templates: [],
    sms: new SMSCalculator(),
    characterUsed: 0,
    partsUsed: 0,
    btnDisable: true,
    mobileValidate: false,
    btnText: "Send Single SMS",
    balance: 0,
    sms_queue: 0,
    rate_per_sms: 0,
    smsServiceStatus: false,
    smsDetails: {},
    user: null,
    sms_stats: {},
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    template: function(v) {
      if (v) {
        let d = {};
        d = this.templates.find((res) => res.value === v);
        if (d.text) this.btnDisable = false;
        this.form.message = d.message;
      }
    },
    "form.message": function(value) {
      this.btnDisable = !value;
      if (value && value.length) {
        const smsData = this.sms.getCount(value);
        this.form.sms_length = smsData.numberOfSMS;
        this.form.msg_type = smsData.msg_type;
        this.smsDetails = smsData;
      } else {
        this.smsDetails = {};
      }
      //   console.log(value);
      //   let smsDetail = { characters: 0, total_sms: 0 };
      //   if (value) smsDetail = this.sms.calculate(value);
      //   this.characterUsed = smsDetail.characters;
      //   this.form.sms_length = smsDetail.total_sms;
      //   this.form.msg_type = smsDetail.msg_type;
    },
  },
  methods: {
    queryString() {
      let json = this.pagination;
      return (
        "?" +
        Object.keys(json)
          .map(function(key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(json[key])
              );
          })
          .join("&")
      );
    },
    searchUser(user) {
      if (user && user.primary_mobile) {
        this.form.mobile = user.primary_mobile;
      } else {
        this.form.mobile = "";
      }
      this.user = user;
    },
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },

    save() {
      this.form.fireFetch = false;
      this.btnDisable = true;
      this.btnText = "Processing";
      this.form
        .store()
        .then((res) => {
          this.getBalance();
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.data.message,
            status: "error",
          });
        })
        .finally((e) => {
          this.btnDisable = false;
          (this.template = ""), (this.btnText = "Send Single SMS");
        });
    },
    getTemplates() {
      this.$rest.get("/api/sms/template?template_list=true").then((data) => {
       const filterTemplate = data.data.data.filter(item => item.company_id !== null)
        this.templates = filterTemplate.map((res) => {
          return { value: res.id, text: res.name, message: res.message };
        });
      });
    },
    mobileChange() {
      if (this.form.mobile.length > 10) {
        this.form.mobile = this.form.mobile.substr(0, 10);
        this.mobileValidate = true;
      } else {
        this.mobileValidate = false;
      }
    },
    getBalance() {
      this.fetchingStats = true;
      this.$rest
        .get("/api/sms/dashboard")
        .then((res) => {
          this.balance = res.data.balance;
          this.sms_queue = res.data.smsqueue;
          this.rate_per_sms = res.data.rate_per_sms;
          this.sms_stats = res.data;
        })
        .finally(() => {
          this.fetchingStats = false;
        });
    },
  },
  mounted() {
    this.smsServiceStatus = true;

    this.getTemplates();
    this.getBalance();
    this.user = null;
  },
};
</script>
<style lang="scss" scoped>
.mobile-validation-message {
  font-size: 11px;
  margin-top: -17px;
}
</style>
